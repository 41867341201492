<template>
<div class="descrption dirr" :style="$vuetify.theme.dark ? '' : 'background-color: #f9fafb'">
    <p class="dirr tl d-none d-sm-flex head_title py-6">{{ $t("ataba_structure") }}</p>
    <hr class="hr">
    <div class="descCon pt-0" >
        <div class="nav d-none d-sm-flex">
            <div class="pt-8">
                <template v-for="(x, i) in descres" >
                    <p @click="showDesc(x.id)" class="tl py-4 nav_items" ><img class="mx-1" src="/assets/img/z-ali.svg">{{x.title}}</p>
                </template>
            </div>
        </div>
        <div class="slider navbar d-flex d-sm-none" :class="{ 'hidden-navbar': !showNavbar }" >
            <p class="dirr tl">{{ $t("ataba_structure") }}</p>
            <v-btn-toggle v-model="toggle_desc">
            <swiper class="swiper dirr mt-6" ref="mySwiper3" :options="swiperOptions3">
                <swiper-slide v-for="(x, i) in descres" :key="x">
                    <v-btn outlined class="src" :value="'desc'+(i+1)" @click="showDesc(x.id)">
                        <p class="tl">{{x.title}}</p>
                    </v-btn>
                </swiper-slide>
            </swiper>
            </v-btn-toggle>
        </div>
        <div class="content " ref="content" :style="$vuetify.theme.dark ? '' : 'background-color: #f8f9fa'">
            <div class="contentCon">

                <v-skeleton-loader v-if="loading" class="border"  max-width="800" type="image, article" ></v-skeleton-loader>

                <template v-else v-for="(i, index) in showres" >

                    <p v-if="descres[i.parent_id]" class="tx content_list">{{ descres[i.parent_id].content }}</p>

                    <v-card class="mb-4 mx-2 content-card" :class="$vuetify.theme.dark ? 'dark-theme' : ''">
                        <v-card-title class="title tl d-flex justify-space-between">
                            {{i.title}}
                        </v-card-title>
                        <hr>
                        <v-card-text>
                            <div class="tx" v-html="formatContent(i.content, index)"></div>
                            <v-btn 
                                v-if="i.content.length > 200"
                                text
                                cercle
                                color="#567530" 
                                @click="toggleExpand(index)"
                                class="mt-2 btn_expand"
                            >
                                {{ isExpanded[index] ? 'عرض أقل' : $t('view_more') }}
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </template>
                
            </div>
        </div>
    </div>
</div>
</template> 
    


<script>
const OFFSET = 200;
export default {
   metaInfo() {
    return {
     
    };
  },
    data() {
    return {
        loading: true,
        load: true,
        descres : [],
        showres: [],
        toggle_desc : 'desc1',
        swiperOptions3: {
            slidesPerView: 'auto',
            freeMode: true,
            keyboard: true,
        },
        showNavbar: true,
        lastScrollPosition: 0,
        scrollValue: 0,
        isExpanded: {},
    }
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    mounted() {  
        document.title = this.$t("ataba_structure");  
        this.lastScrollPosition = window.pageYOffset
        window.addEventListener('scroll', this.onScroll)
        const viewportMeta = document.createElement('meta')
        viewportMeta.name = 'viewport'
        viewportMeta.content = 'width=device-width, initial-scale=1'
        document.head.appendChild(viewportMeta);
    }, 
    created () {
        this.getDescs ();
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods:{
        getDescs () {
            this.$axios.get("structure/", {
            })
            .then(res => {
                this.load = false;
                this.descres = res.data;
                this.showDesc(res.data[0].id);
                //console.log(res.data);
            })
            .catch(() => {
            });
        },
        showDesc (x) {
            this.$axios.get("structure/"+x, {
            })
            .then(res => {
                this.loading = false;
                this.showres = res.data;
                this.isExpanded = {}; // Reset expansion state
                window.scrollTo(0,0);
                this.$refs.content.scrollTop = 0;
                //console.log(res.data);
            })
            .catch(() => {
            });
        },
        onScroll () {
            if (window.pageYOffset < 0) {return}
            if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {return}
            this.showNavbar = window.pageYOffset < this.lastScrollPosition
            this.lastScrollPosition = window.pageYOffset
        },
        truncateText(text, length) {
            const strippedText = text.replace(/<[^>]*>/g, '');
            if (strippedText.length <= length) return text;
            return strippedText.substring(0, length) + '...';
        },
        formatContent(content, index) {
            if (!this.isExpanded[index]) return this.truncateText(content, 200);
            return content.replace(/\n/g, '<br>');
        },
        toggleExpand(index) {
            this.$set(this.isExpanded, index, !this.isExpanded[index]);
        },
    },
}
</script>
<style scoped>
/* f8f9fa */
.descrption .descCon .nav {
    max-width: 330px !important;
    border-left: 1px solid #ccc;

}

.content-card {
    transition: all 0.3s ease;
    border-radius: 8px;
}

.content-card.dark-theme {
    background-color: #1E1E1E;
    color: white;
}

.content-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
body * {
    font-family: 'readex'!important;
}
.tx {
    line-height: 1.6;
    padding: 8px 0;
}
.head_title {
    font-size: 48px;
    font-weight: 700;
}
.hr {
  height: 2px; 
  background: #ccc; 
  background: linear-gradient(to right, rgba(0,0,0,0) , #e0e4dc, rgba(0,0,0,0));
  border: 0;
}
.descrption::after {
    height: 50px!important;
}
.btn_expand {
    letter-spacing: 0; border: solid thin #567530; border-radius: 15px;
}
.nav_items {
    text-align: start; cursor: pointer; font-size: 15px;
}
.content_list {
    font-size: 15px!important;
    padding: 10px!important;
}
</style>